<template>
	<layouts-store-minimum>
		<div class="vstack gap-5 text-center mt-10">
			<!-- Avatar -->
			<div class="avatar avatar-xl rounded-circle mx-auto" v-if="logoExist">
				<img :src="current_store.logo_url" alt="logo" @load="logoReady = true" v-show="logoReady">
				<Skeletor width="100%" height="100%" class="rounded-circle" v-show="!logoReady" />
			</div>
			<div class="h3 lh-1 text-dark" @click="showInfo" v-show="isDefined">{{ current_store.name }}</div>
			<Skeletor width="50%" height="30" class="h3 lh-1 mx-auto rounded rounded-3" v-show="!isDefined" />
		</div>
		<div class="wrapper vstack gap-5 mt-12" v-if="isDefined">
			<div v-for="link in current_store.links" v-bind:key="link.id">
				<a :href="link.url" target="_blank" class="btn btn-outline-dark border border-2 rounded-5 border-dark w-100">{{ link.description }}</a>
			</div>
		</div>
		<div class="wrapper vstack gap-5 mt-12" v-if="!isDefined">
			<div v-for="i in 3" v-bind:key="i">
				<Skeletor width="100%" height="46" class="border border-2 rounded-5" />
			</div>
		</div>
	</layouts-store-minimum>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { isDefined, current_store } = composableStore();
			const logoExist = ref(true);
			const logoReady = ref(false);

			const imagesLoad = () => {
				logoExist.value = true;
				logoReady.value = false;

				if(isDefined.value) {
					logoExist.value = !!current_store.value.logo_url;
				}
			}

			watch(isDefined, imagesLoad);
			onMounted(imagesLoad);

			return { isDefined, current_store, logoExist, logoReady };
		}
	}
</script>